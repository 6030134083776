import React from 'react';
import {Button} from 'react-bootstrap';
import {PlayStoreURL, AppStoreURL} from '../config';

/**
 *  @abstract
 *  Operations View that Ads Sales and Promos extend from
 */
class HomeView extends React.Component {

   getStoreUrl() {
     const userAgent = navigator.userAgent || navigator.vendor || window.opera;

     if (/android/i.test(userAgent)) {
        return PlayStoreURL;
     }

     if (/iPad|iPhone|iPod/.test(userAgent)) {
       return AppStoreURL;
     }

     return PlayStoreURL;
   }

  render() {
    return (
      <div id="home-view" className="content view d-flex flex-row flex-fill">

        <div className="cover-image"></div>

        <div className="section">
          <h2 className="title">512 Games</h2>
          <p className="text">A company dedicated to the creation of compelling social games for mobile platforms.</p>
        </div>

        <div className="section app-section">
          <div className="app-icon"/>
          <h2 className="title"><span>Featured Game</span>Play Pals: Word Bobble</h2>
          <p className="text">Challenge your friends and family to this addictive new take on a classic word game!</p>


          <Button variant="primary" size="lg" href={this.getStoreUrl()} target="_blank">Play</Button>

        </div>


      </div>
    );
  }

}

export default HomeView;

