import React from 'react';

/**
 *  @abstract
 *  Operations View that Ads Sales and Promos extend from
 */
class ContactView extends React.Component {


  render() {
    return (
      <div id="contact-view" className="content view d-flex flex-row flex-fill">

        <div className="section">
          <h2 className="title">Contact Us</h2>
          <p className="text">
            <b>General Questions</b><a href="mailto:info@512games.com">info@512games.com</a>
          </p>
          <p className="text">
            <b>Support</b><a href="mailto:support@512games.com">support@512games.com</a>
          </p>

         {/* <img className="logo" src="../images/logo.png"/>*/}
        </div>

      </div>
    );
  }

}

export default ContactView;

