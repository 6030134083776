import React from 'react';
import {Switch, DefaultRoute, Route, BrowserRouter as Router, Redirect} from 'react-router-dom';

import {Nav, Navbar} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import ScrollToTop from './ScrollToTop';
import HomeView from './HomeView';
import ContactView from './ContactView';
import TermsOfServiceView from './TermsOfServiceView';
import PrivacyPolicyView from './PrivacyPolicyView';
import {FacebookURL, TwitterURL} from '../config';

/**
 * Outer Application container
 */
class App extends React.Component {

  render() {

    return (
      <Router>
        <ScrollToTop/>

        <div id="app">

          <Navbar id="top-nav">

            <div className="content">
              <a id="logo" href="/"/>

              <div className="social-icons">
                <a href={FacebookURL} target="_blank">1
                  <div className="social-icon facebook-icon"/>
                </a>
                <a href={TwitterURL} target="_blank">
                  <div className="social-icon twitter-icon"/>
                </a>
              </div>
            </div>


            {/*  <Nav className='nav-links' activeKey="/">
              <LinkContainer key="home" to="/">
                <Nav.Link>Company</Nav.Link>
              </LinkContainer>
            </Nav>*/}
          </Navbar>

          <Switch>

            <Route path="/contact" component={ContactView}/>

            <Route path="/privacy-policy" component={PrivacyPolicyView}/>

            <Route path="/terms-of-service" component={TermsOfServiceView}/>

            <Route path="/" component={HomeView}/>

          </Switch>


          <Navbar id="bottom-nav" className="justify-content-center">
            <Nav className='nav-links'>
              <LinkContainer key="contact" to="/contact">
                <Nav.Link>Contact</Nav.Link>
              </LinkContainer>

              <LinkContainer key="privacy-policy" to="/privacy-policy">
                <Nav.Link>Privacy Policy</Nav.Link>
              </LinkContainer>

              <LinkContainer key="terms-of-service" to="/terms-of-service">
                <Nav.Link>Terms of Service</Nav.Link>
              </LinkContainer>
            </Nav>

            <div className="copy">
              ©2022 512 Games LLC.
            </div>
          </Navbar>

        </div>


      </Router>
    );
  }
}

export default App;
