import React from 'react';

/**
 *  @abstract
 *  Operations View that Ads Sales and Promos extend from
 */
class PrivacyPolicyView extends React.Component {


  render() {
    return (
      <div id="privacy-view" className="content view d-flex flex-row flex-fill">

        <div className="section">
          <h2 className="title">Privacy Policy</h2>
          <p>Your privacy is important to us. It is 512 Games' policy to respect your privacy
            regarding any information we may collect from you across our website or mobile
            applications.</p>
          <p>We only ask for personal information when we truly need it to provide a service to you.
            We collect it by fair and lawful means, with your knowledge and consent. We also let you
            know why we're collecting it and how it will be used.</p>
          <p>We only retain collected information for as long as necessary to provide you with your
            requested service. What data we store, we'll protect within commercially acceptable
            means to prevent loss and theft, as well as unauthorized access, disclosure, copying,
            use or modification.</p>
          <p>We don't share any of user information publicly or with third-parties, except when
            required to by law.</p>
          <p>You are free to refuse our request for your personal information, with the
            understanding that we may be unable to provide you with some of your desired
            services.</p>
          <p>Your continued use of our apps will be regarded as acceptance of our practices around
            privacy and personal information</p>

          <p>The following is a comprehensive list of data we currently process:</p>

          <p>
            <ul class="bullets">
              <li>User's Device Information and Identifier</li>
              <li>User's Name</li>
              <li>User's Facebook Identifier*</li>
              <li>URL to User's current Facebook Icon*</li>
              <li>List of User's Facebook Friends who also have the App Installed*</li>

            </ul>

            <b>*Only applies if the user chooses to connect to Facebook
              while inside the app.
            </b>
          </p>

          <p>If you have any more questions about our data policy, or if you wish to permanently
            remove your account and all related data from our servers, please contact <a
              href="mailto:info@512games.com">support@512games.com</a></p>
          <p>This policy is effective as of 12 October 2020.</p>
        </div>
      </div>
    );
  }

}

export default PrivacyPolicyView;

